import { EmptyContent, EntityDetail, LoadingSpinner, RestrictedSection } from '@/components'
import { If } from '@/components/@misc'
import { usePermissions, useQueryFindByIdCounterpartyAccount } from '@/hooks'
import { PageLayout } from '@/layouts'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { CounterpartyAccount } from '@webapps/numeral-ui-core'
import { chain, isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { CounterpartyAccountVerificationFooter } from './@components'
import {
    COUNTERPARTY_ACCOUNT_VERIFICATIONS_ROW_CUSTOMIZERS,
    COUNTERPARTY_ACCOUNT_VERIFICATIONS_ROWS
} from './CounterpartyAccountVerifications.page.const'
import { CounterpartyAccountVerificationCustomRows } from './CounterpartyAccountVerifications.types'
import { useMemo } from 'react'

export const CounterpartyAccountVerificationsPage: React.FC = () => {
    const { uuid } = useParams()
    const { hasPermission } = usePermissions()
    const query = useQueryFindByIdCounterpartyAccount(uuid)
    const canVerifyCounterpartyAccount = useMemo<boolean>(() => {
        const hasVerifyCounterpartyAccountsPermission = hasPermission(
            PERMISSION.COUNTERPARTIES_VERIFY_COUNTERPARTY_ACCOUNTS
        )
        const hasNoAccountVerification = chain(query.data?.account_verification).isNull().value()

        return hasVerifyCounterpartyAccountsPermission && hasNoAccountVerification
    }, [query, hasPermission])

    return (
        <PageLayout>
            <RestrictedSection
                feature={ENTITY_FEATURE.COUNTERPARTY_ACCOUNT_VERIFICATION}
                permission={PERMISSION.COUNTERPARTIES_VIEW_COUNTERPARTY_ACCOUNTS}>
                {(() => {
                    switch (true) {
                        case query?.isLoading: {
                            return <LoadingSpinner />
                        }

                        case isEmpty(query?.data):
                        case query?.isError: {
                            return <EmptyContent />
                        }

                        default: {
                            return (
                                <>
                                    <EntityDetail<CounterpartyAccount, CounterpartyAccountVerificationCustomRows>
                                        data={query.data}
                                        rows={COUNTERPARTY_ACCOUNT_VERIFICATIONS_ROWS}
                                        rowCustomizers={COUNTERPARTY_ACCOUNT_VERIFICATIONS_ROW_CUSTOMIZERS}
                                    />
                                    <If condition={canVerifyCounterpartyAccount}>
                                        <CounterpartyAccountVerificationFooter counterpartyAccountId={query.data?.id} />
                                    </If>
                                </>
                            )
                        }
                    }
                })()}
            </RestrictedSection>
        </PageLayout>
    )
}
