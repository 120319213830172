import { useServices, useUserAccess } from '@/hooks'
import { filterUserAccessQueryParams } from '@/services'
import { MutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { PaymentOrder, Uuid } from '@webapps/numeral-ui-core'
import { noop } from 'lodash'

export function useMutationCancelByIdPaymentOrder(
    id?: Uuid,
    options?: MutationOptions<Partial<PaymentOrder> | void, Error>
) {
    const { userAccess } = useUserAccess()
    const { paymentOrdersService } = useServices()
    const queryClient = useQueryClient()
    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(userAccess)
    }

    return useMutation<PaymentOrder | void, Error>({
        ...options,
        mutationKey: [paymentOrdersService.url, { id }],
        mutationFn(data) {
            return paymentOrdersService.cancelPaymentOrder(id as string, {
                params: paramsWithUserAccess
            })
        },
        async onSuccess(...args) {
            options?.onSuccess?.(...args)

            queryClient.setQueryData([paymentOrdersService.url, { id }], args[0])

            await queryClient
                .invalidateQueries({
                    queryKey: [paymentOrdersService.url]
                })
                .catch(noop)
        }
    })
}
