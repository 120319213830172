import { useServices, useUserAccess } from '@/hooks'
import { filterUserAccessQueryParams } from '@/services'
import { MutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { Transaction, TransactionUpdateData, Uuid } from '@webapps/numeral-ui-core'
import { noop } from 'lodash'
import { AxiosError } from 'axios'
import { APIError } from '@/types'

export function useMutationUpdateByIdTransaction(
    id?: Uuid,
    options?: MutationOptions<Transaction, AxiosError<APIError<Transaction>>, TransactionUpdateData>
) {
    const { userAccess } = useUserAccess()
    const { transactionsService } = useServices()
    const queryClient = useQueryClient()
    const paramsWithUserAccess = { ...filterUserAccessQueryParams(userAccess) }

    return useMutation<Transaction, AxiosError<APIError<Transaction>>, TransactionUpdateData>({
        ...options,
        mutationKey: [transactionsService.url, { id }],
        mutationFn(data) {
            return transactionsService.updateById(id as string, data, {
                params: paramsWithUserAccess
            })
        },
        async onSuccess(...args) {
            options?.onSuccess?.(...args)

            queryClient.setQueryData([transactionsService.url, { id }], args[0])

            await queryClient
                .invalidateQueries({
                    queryKey: [transactionsService.url]
                })
                .catch(noop)
        }
    })
}
