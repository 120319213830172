import type { UndefinedInitialDataOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { UserInfo } from '@webapps/numeral-ui-core'
import { useServices } from '@/hooks'

export function useQueryFindCurrentUserInfo(options?: Partial<UndefinedInitialDataOptions<UserInfo, Error>>) {
    const { userService } = useServices()
    return useQuery<UserInfo, Error>({
        ...options,
        queryKey: [userService.url, 'user_info'],
        queryFn({ signal }) {
            return userService.findCurrentUserInfo({ signal })
        }
    })
}
