import { usePermissions, useServices, useUserAccess } from '@/hooks'
import { filterUserAccessQueryParams, PERMISSION } from '@/services'
import { type UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query'
import { NumeralEvent, Uuid } from '@webapps/numeral-ui-core'

export function useQueryFindByIdEvent(id?: Uuid, options?: Partial<UndefinedInitialDataOptions<NumeralEvent, Error>>) {
    const { eventsService } = useServices()
    const { userAccess } = useUserAccess()
    const { hasPermission } = usePermissions()

    const isQueryEnabled = hasPermission(PERMISSION.DEVELOPERS_VIEW_EVENTS) && options?.enabled
    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(userAccess)
    }

    return useQuery<NumeralEvent, Error>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [eventsService.url, { id, ...paramsWithUserAccess }],
        queryFn({ signal }) {
            return eventsService.findById(id as string, { signal, params: paramsWithUserAccess })
        }
    })
}
