import { Select } from '@/components/@misc'
import { FormikInput } from '@/types'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { DirectDebitMandateSequence, DirectDebitMandateSequenceSchema } from '@webapps/numeral-ui-core'
import { useField } from 'formik'
import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

export const DirectDebitMandateSequenceInput: React.FC<FormikInput> = ({
    name,
    value,
    isPlaceholderDisplayed,
    ...inputProps
}) => {
    const intl = useIntl()
    const [field, meta, helpers] = useField<DirectDebitMandateSequence>({ name, value })
    const placeholder = useMemo<string | undefined>(() => {
        if (!isPlaceholderDisplayed) {
            return
        }
        return intl.formatMessage({ id: 'app.common.form.input.select.placeholder' })
    }, [intl, isPlaceholderDisplayed])
    const isInvalid = meta.touched && !!meta.error

    return (
        <FormControl key={name} isInvalid={isInvalid}>
            <FormLabel htmlFor={name}>
                <FormattedMessage id={`app.common.form.input.${name}.label`} />
            </FormLabel>
            <Select<DirectDebitMandateSequence>
                {...inputProps}
                {...field}
                id={name}
                placeholder={placeholder}
                onChange={helpers.setValue}
                isInvalid={isInvalid}
                options={DirectDebitMandateSequenceSchema.options}
            />
            <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
    )
}
