import { DirectDebitMandate } from '@webapps/numeral-ui-core'
import { BULLET_SEPARATOR, SPACE_SYMBOL } from '@/constants'
import { IntlShape } from 'react-intl'

export function getDirectDebitMandateOptionLabelWithIntl(intl: IntlShape) {
    return function getOptionLabel(option: DirectDebitMandate): string {
        const receivingAccountHolderName = option?.receiving_account?.holder_name
        let result = ''

        if (option?.sequence) {
            const formattedSequence = intl.formatMessage({
                id: `api.payment.direct_debit_mandate.sequence.${option.sequence}`
            })

            result += formattedSequence
        }

        if (receivingAccountHolderName) {
            result += `${BULLET_SEPARATOR}${SPACE_SYMBOL}${receivingAccountHolderName}`
        }

        if (option?.reference) {
            result += `${SPACE_SYMBOL}${BULLET_SEPARATOR}${SPACE_SYMBOL}${option.reference}`
        }

        return result
    }
}
