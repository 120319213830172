import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { useQueryFindAllUserAccesses, useQueryFindCurrentUserInfo, useUserAccess } from '@/hooks'
import { getUserFullName } from '@/services'
import { Button, ButtonProps, Flex, Skeleton, Text } from '@chakra-ui/react'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { chain } from 'lodash'
import { useMemo } from 'react'
import { MAIN_NAVIGATION_LEGAL_ENTITY_CONTAINER_SIZES } from './MainNavigationAccountDropdown.const'
import { getEntityLogoSizeByMenuState } from './MainNavigationAccountDropdown.utils'
import { MainNavigationAccountDropdownMenu } from './MainNavigationAccountDropdownMenu'

interface MainNavigationAccountDropdownProps {
    isCollapsed: boolean
}

export const MainNavigationAccountDropdown: React.FC<MainNavigationAccountDropdownProps> = ({ isCollapsed }) => {
    const { userAccess } = useUserAccess()
    const queryUserInfo = useQueryFindCurrentUserInfo()
    const queryUserAccesses = useQueryFindAllUserAccesses()
    const isLoading = useMemo<boolean>(() => {
        return queryUserInfo.isLoading || queryUserAccesses.isLoading
    }, [queryUserInfo, queryUserAccesses])

    const userFullName = useMemo<string>(() => {
        return getUserFullName(queryUserInfo.data, EMPTY_VALUE_PLACEHOLDER)
    }, [queryUserInfo])

    const userAccessName = useMemo<string>(() => {
        return chain(userAccess)
            .get('name', userAccess?.legal_entity_id || EMPTY_VALUE_PLACEHOLDER)
            .value()
    }, [userAccess])

    const MainNavigationAccountInitials = useMemo(() => {
        const dimension = getEntityLogoSizeByMenuState(isCollapsed)
        const legalEntityInitialLetter = chain<string>(userAccess?.name).head().defaultTo('⚠️').capitalize().value()
        const propsFlex = {
            height: dimension,
            width: dimension,
            flex: `0 0 ${dimension}`,
            color: 'white',
            fontWeight: '600',
            fontSize: '20px',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 'var(--numeral-ui-primary-border-radius)',
            cursor: 'pointer',
            margin: '0 auto',
            backgroundColor: `numeralPurple.500${isCollapsed ? '!important' : ''}`,
            ...(isCollapsed && { _hover: { opacity: 0.9 } })
        }

        return <Flex {...propsFlex}>{legalEntityInitialLetter}</Flex>
    }, [isCollapsed, userAccess])

    const propsButton = useMemo<ButtonProps>(() => {
        return {
            variant: 'ghost',
            height: `${MAIN_NAVIGATION_LEGAL_ENTITY_CONTAINER_SIZES.LARGEST}px`,
            rightIcon: <FontAwesomeIcon icon={faChevronDown} size="xs" color="gray" />
        }
    }, [])

    if (isLoading) {
        const propsSkeleton = {
            height: isCollapsed
                ? `${MAIN_NAVIGATION_LEGAL_ENTITY_CONTAINER_SIZES.SMALL}px`
                : `${MAIN_NAVIGATION_LEGAL_ENTITY_CONTAINER_SIZES.LARGEST}px`
        }
        return <Skeleton {...propsSkeleton} />
    }

    if (isCollapsed) {
        return <MainNavigationAccountDropdownMenu>{MainNavigationAccountInitials}</MainNavigationAccountDropdownMenu>
    }

    return (
        <MainNavigationAccountDropdownMenu>
            <Flex gap="8px" align="center" paddingX="8px">
                {MainNavigationAccountInitials}
                <Button
                    variant="secondary"
                    display="flex"
                    flex="1"
                    padding="8px"
                    justifyContent="space-between"
                    {...propsButton}>
                    <Flex noOfLines={2} direction="column" textAlign="left" justifyContent="space-between">
                        <Text
                            as="span"
                            noOfLines={1}
                            display="block"
                            color="gray.800"
                            fontWeight="bold"
                            fontSize="medium"
                            margin="0 12px 4px 0">
                            {userAccessName}
                        </Text>
                        <Text as="span" noOfLines={1} display="block" color="gray.500">
                            {userFullName}
                        </Text>
                    </Flex>
                </Button>
            </Flex>
        </MainNavigationAccountDropdownMenu>
    )
}
