import { useActivatedFeatures, usePermissions, useServices, useUserAccess } from '@/hooks'
import { ENTITY_FEATURE, filterUserAccessQueryParams, PERMISSION } from '@/services'
import { useQuery } from '@tanstack/react-query'
import { Return, Uuid } from '@webapps/numeral-ui-core'
import type { UndefinedInitialDataOptions } from '@tanstack/react-query/src/queryOptions'

export function useQueryFindByIdReturn(id?: Uuid, options?: Partial<UndefinedInitialDataOptions<Return, Error>>) {
    const { returnsService } = useServices()
    const { userAccess } = useUserAccess()
    const { isFeatureActivated } = useActivatedFeatures()
    const { hasPermission } = usePermissions()

    const isQueryEnabled =
        isFeatureActivated(ENTITY_FEATURE.RETURN) && hasPermission(PERMISSION.PAYMENTS_VIEW_RETURNS) && options?.enabled
    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(userAccess)
    }

    return useQuery<Return, Error>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [returnsService.url, { id }],
        queryFn({ signal }) {
            return returnsService.findById(id as string, { signal, params: paramsWithUserAccess })
        }
    })
}
