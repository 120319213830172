import { usePrevious, useQueryFindCurrentUserInfo, useUserAccess } from '@/hooks'
import { isEqualWith } from 'lodash'
import { useEffect } from 'react'
import { getHashedEmail } from './useFullStoryNotifyIdentity.utils'

let isIdentityForwarded = false

export const useFullStoryNotifyIdentity = () => {
    const { userAccess } = useUserAccess()
    const userInfoQuery = useQueryFindCurrentUserInfo()
    const prevLegalEntity = usePrevious<string | undefined>(userAccess?.legal_entity_id)

    useEffect(() => {
        const isUserAccessUpdated = userAccess && !isEqualWith(userAccess?.legal_entity_id, prevLegalEntity)
        const shouldNotifyIdentity = !isIdentityForwarded || isUserAccessUpdated

        if (!globalThis.window.FS || !shouldNotifyIdentity || !userInfoQuery.isSuccess) {
            return
        }

        const notifyIdentity = async () => {
            isIdentityForwarded = true
            const userEmail = userInfoQuery.data.email
            const hashedEmail = await getHashedEmail(userInfoQuery.data.email)

            if (!userEmail || !hashedEmail) {
                return
            }

            globalThis.window.FS?.identify?.(hashedEmail, {
                email: userEmail,
                legal_entity_id: userAccess?.legal_entity_id
            })
        }
        notifyIdentity()
    }, [userAccess, userInfoQuery, prevLegalEntity])
}
