import { Form, FormPageFooter, FormSection } from '@/components'
import { AmountInput, CurrencyInput, DateInput, TextInput } from '@/components/@inputs'
import { DEFAULT_DATE_TEMPLATE_FORMAT, HIGHLIGHTED_CURRENCY_CODES } from '@/constants'
import { CustomFieldsSection, PaymentOrderTreasuryOptionInput } from '@/pages'
import { isSDDPayment, isSwiftPayment, isTreasuryPayment } from '@/services'
import { Box, Divider, Flex } from '@chakra-ui/react'
import { ApiObjectTypeSchema, PaymentOrder } from '@webapps/numeral-ui-core'
import { noop } from 'lodash'
import React, { useMemo } from 'react'
import { PaymentOrderRetryRuleSection, PaymentOrderTypeDirectionInput } from '../../../@forms'
import {
    PaymentOrderConfidentialityOptionInput,
    PaymentOrderConnectedAccountInput,
    PaymentOrderDirectDebitMandateSection,
    PaymentOrderFeeOptionInput,
    PaymentOrderOriginatingAccountSection,
    PaymentOrderReceivingAccountSection
} from './@components'
import { PAYMENT_ORDER_REFERENCE_MAX_LENGTH } from './PaymentOrderCreate.form.const'
import { PaymentOrderFormProps } from './PaymentOrderCreate.form.types'
import { getPaymentOrderFormInitialValues } from './PaymentOrderCreate.form.utils'
import { If } from '@/components/@misc'
import { format } from 'date-fns'

export const PaymentOrderForm: React.FC<PaymentOrderFormProps> = ({ disabled, loading, onSubmit = noop }) => {
    const initialValues = useMemo<Partial<PaymentOrder>>(getPaymentOrderFormInitialValues, [])

    return (
        <Form<Partial<PaymentOrder>> initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, handleSubmit, dirty }) => {
                const hasSwiftPaymentType = isSwiftPayment(values.type)
                const hasTreasuryPaymentType = isTreasuryPayment(values.type)
                const hasSDDPaymentType = isSDDPayment(values.type, values.direction)
                const hasCurrencyDisabled = disabled || !(hasSwiftPaymentType || hasTreasuryPaymentType)
                const today = format(new Date(), DEFAULT_DATE_TEMPLATE_FORMAT)

                return (
                    <>
                        <FormSection titleId="app.common.form.sections.general_information.title">
                            <PaymentOrderConnectedAccountInput
                                name="connected_account_id"
                                isDisabled={disabled}
                                isRequired={true}
                            />
                            <PaymentOrderTypeDirectionInput
                                name="type"
                                isDisabled={disabled}
                                isRequired={true}
                                isFirstOptionPreselected={true}
                            />
                            <PaymentOrderFeeOptionInput
                                name="fee_option"
                                isDisabled={disabled}
                                isRequired={hasSwiftPaymentType}
                                customPlaceholderKey="app.payments.payment_orders.create.form.inputs.fee_option.placeholder"
                            />
                            <PaymentOrderTreasuryOptionInput
                                name="treasury_option"
                                customPlaceholderKey="app.payments.payment_orders.create.form.inputs.treasury_option.placeholder"
                                isDisabled={disabled}
                                isRequired={hasTreasuryPaymentType}
                            />
                            <PaymentOrderConfidentialityOptionInput
                                name="confidentiality_option"
                                customPlaceholderKey="app.payments.payment_orders.create.form.inputs.confidentiality_option.placeholder"
                                isDisabled={disabled}
                            />
                            <Flex gap="16px">
                                <Box width="120px">
                                    <CurrencyInput
                                        name="currency"
                                        isDisabled={hasCurrencyDisabled}
                                        highlightedCurrencyCodes={HIGHLIGHTED_CURRENCY_CODES}
                                        isRequired={true}
                                        isClearable={false}
                                    />
                                </Box>
                                <AmountInput
                                    name="amount"
                                    customPlaceholderKey="app.payments.payment_orders.create.form.inputs.amount.placeholder"
                                    isDisabled={disabled}
                                    isRequired={true}
                                />
                            </Flex>
                            <TextInput
                                name="reference"
                                customPlaceholderKey="app.payments.payment_orders.create.form.inputs.reference.placeholder"
                                maxLength={PAYMENT_ORDER_REFERENCE_MAX_LENGTH}
                                isDisabled={disabled}
                                isRequired={true}
                            />
                            <If condition={hasSDDPaymentType}>
                                <DateInput
                                    name="requested_execution_date"
                                    isDisabled={disabled}
                                    value={values?.requested_execution_date}
                                    min={today}
                                />
                            </If>
                        </FormSection>

                        <PaymentOrderOriginatingAccountSection isDisabled={disabled} />

                        <If condition={hasSDDPaymentType}>
                            <PaymentOrderDirectDebitMandateSection isDisabled={disabled} />
                        </If>

                        <PaymentOrderReceivingAccountSection isDisabled={disabled} />

                        <Divider borderWidth="1px" marginTop="8px" marginBottom="16px" />

                        <PaymentOrderRetryRuleSection isDisabled={disabled} isSectionDisplayed={true} />

                        <CustomFieldsSection<PaymentOrder>
                            objectType={ApiObjectTypeSchema.enum.payment_order}
                            isDisabled={disabled}
                        />

                        <FormPageFooter
                            onSubmit={handleSubmit}
                            isDisabled={disabled || !dirty}
                            isLoading={loading}
                            submitLabelMessageId="app.payments.payment_orders.create.form.actions.submit.label"
                            showUnsavedChangesWarning={true}
                        />
                    </>
                )
            }}
        </Form>
    )
}
