import { useServices, useUserAccess } from '@/hooks'
import { filterUserAccessQueryParams, RECONCILIATIONS_REJECTIONS } from '@/services'
import { APIError } from '@/types'
import { MutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { ReconciliationCreateOrUpdateData } from '@webapps/numeral-ui-core'
import { AxiosError } from 'axios'
import { noop } from 'lodash'

export function useMutationCreateConcurrentReconciliations(
    options?: MutationOptions<
        string | void,
        AxiosError<APIError<ReconciliationCreateOrUpdateData>>,
        ReconciliationCreateOrUpdateData[]
    >
) {
    const { userAccess } = useUserAccess()
    const { reconciliationsService } = useServices()
    const queryClient = useQueryClient()
    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(userAccess)
    }

    return useMutation<
        string | void,
        AxiosError<APIError<ReconciliationCreateOrUpdateData>>,
        ReconciliationCreateOrUpdateData[]
    >({
        ...options,
        mutationKey: [reconciliationsService.url],
        async mutationFn(data) {
            const concurrentCalls = data.map((item) => {
                return reconciliationsService.createReconciliation(item, {
                    params: paramsWithUserAccess
                })
            })
            const responses = await Promise.allSettled(concurrentCalls)
            const rejections = responses?.filter(({ status }) => status === 'rejected')

            switch (true) {
                case rejections?.length === responses.length: {
                    return Promise.reject(new Error(RECONCILIATIONS_REJECTIONS.FULL_REJECTION))
                }

                case rejections?.length > 0: {
                    return Promise.resolve(RECONCILIATIONS_REJECTIONS.PARTIAL_REJECTION)
                }
            }
        },
        async onSuccess(...args) {
            options?.onSuccess?.(...args)

            await queryClient
                .invalidateQueries({
                    queryKey: [reconciliationsService.url]
                })
                .catch(noop)
        }
    })
}
