import { useActivatedFeatures, usePermissions, useServices, useUserAccess } from '@/hooks'
import { ENTITY_FEATURE, filterUserAccessQueryParams, PERMISSION } from '@/services'
import { useQuery } from '@tanstack/react-query'
import { Uuid, VirtualAccount } from '@webapps/numeral-ui-core'
import type { UndefinedInitialDataOptions } from '@tanstack/react-query/src/queryOptions'

export function useQueryFindByIdVirtualAccount(
    id?: Uuid,
    options?: Partial<UndefinedInitialDataOptions<VirtualAccount, Error>>
) {
    const { virtualAccountsService } = useServices()
    const { userAccess } = useUserAccess()
    const { isFeatureActivated } = useActivatedFeatures()
    const { hasPermission } = usePermissions()

    const isQueryEnabled =
        isFeatureActivated(ENTITY_FEATURE.COUNTERPARTY) &&
        hasPermission(PERMISSION.COUNTERPARTIES_VIEW_VIRTUAL_ACCOUNTS) &&
        options?.enabled
    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(userAccess)
    }

    return useQuery<VirtualAccount, Error>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [virtualAccountsService.url, { id }],
        queryFn({ signal }) {
            return virtualAccountsService.findById(id as string, { signal, params: paramsWithUserAccess })
        }
    })
}
