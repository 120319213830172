import { FormSection } from '@/components'
import { OptionalAccountHolderAddress } from '@/components/@forms'
import { TextInput } from '@/components/@inputs'
import { If } from '@/components/@misc'
import { useQueryFindByIdConnectedAccount } from '@/hooks'
import { isFinancialInstitutionConnectedAccount, isSDDPayment } from '@/services'
import { bankCodeValidator } from '@/utils/@validators'
import { PaymentOrder } from '@webapps/numeral-ui-core'
import { useFormikContext } from 'formik'
import { useEffect, useMemo } from 'react'
import { computePartialPaymentOrderFormFields } from '../PaymentOrderCreate.form.utils'

export interface PaymentOrderOriginatingAccountSectionProps {
    isDisabled?: boolean
}

export const PaymentOrderOriginatingAccountSection: React.FC<PaymentOrderOriginatingAccountSectionProps> = ({
    isDisabled
}) => {
    const { getFieldProps, setFieldValue } = useFormikContext<PaymentOrder>()
    const { value: connectedAccountId } = getFieldProps('connected_account_id')
    const { value: type } = getFieldProps('type')
    const { value: direction } = getFieldProps('direction')

    const hasConnectedAccountId = useMemo<boolean>(() => globalThis.Boolean(connectedAccountId), [connectedAccountId])
    const queryConnectedAccount = useQueryFindByIdConnectedAccount(connectedAccountId, {
        enabled: hasConnectedAccountId && !isDisabled
    })
    const isFinancialInstitutionContext = useMemo<boolean>(
        () => isFinancialInstitutionConnectedAccount(queryConnectedAccount.data),
        [queryConnectedAccount]
    )
    const isSDDContext = useMemo(() => isSDDPayment(type, direction), [type, direction])

    useEffect(() => {
        if (connectedAccountId) {
            const { originating_account } = computePartialPaymentOrderFormFields(queryConnectedAccount.data)
            setFieldValue('originating_account', originating_account)
        }
    }, [connectedAccountId, queryConnectedAccount.data])

    return (
        <If condition={queryConnectedAccount.isLoading || isFinancialInstitutionContext}>
            <FormSection titleId="app.payments.payment_orders.create.form.sections.originating_account.title">
                <TextInput name="originating_account.holder_name" isDisabled={isDisabled} isRequired={true} />
                <TextInput name="originating_account.account_number" isDisabled={isDisabled} isRequired={true} />
                <TextInput
                    name="originating_account.bank_code"
                    validator={bankCodeValidator}
                    isDisabled={true}
                    isRequired={true}
                />
                <If condition={isSDDContext}>
                    <TextInput name="originating_account.creditor_identifier" isRequired={true} />
                </If>
                <OptionalAccountHolderAddress sectionName="originating_account" isDisabled={isDisabled} />
            </FormSection>
        </If>
    )
}
