import {
    ConnectedAccountsService as PublicConnectedAccountsService,
    ConnectedAccountsServiceFindAllQueryOptions,
    Deprecated,
    extractResponseData,
    List,
    ServicesConfiguration
} from '@webapps/numeral-ui-core'
import {
    AggregateAllConnectedAccountsQueryOptions,
    ConnectedAccountExpanded,
    ConnectedAccountGroup
} from './ConnectedAccounts.service.types'
import { Group } from '@/types'

export class ConnectedAccountsService extends PublicConnectedAccountsService {
    @Deprecated('Moved aggregate at `Balance` level.')
    async aggregateAll(
        params?: AggregateAllConnectedAccountsQueryOptions,
        config?: ServicesConfiguration
    ): Promise<Group<ConnectedAccountExpanded, ConnectedAccountGroup>> {
        return this.httpClient
            .get<Group<ConnectedAccountExpanded, ConnectedAccountGroup>>(`${super.url}/aggregate`, {
                params,
                ...config
            })
            .then(extractResponseData)
    }

    async findAllExpanded(
        params?: Partial<ConnectedAccountsServiceFindAllQueryOptions>,
        config?: ServicesConfiguration
    ): Promise<List<ConnectedAccountExpanded>> {
        return this.httpClient
            .get<List<ConnectedAccountExpanded>>(`${super.url}/expanded`, { params, ...config })
            .then(extractResponseData)
    }
}
