import { useServices, useUserAccess } from '@/hooks'
import { filterUserAccessQueryParams } from '@/services'
import { MutationOptions, useMutation } from '@tanstack/react-query'
import { ExportPayload, TransactionsServiceFindAllQueryOptions } from '@webapps/numeral-ui-core'

export function useMutationExportBalances(
    options?: MutationOptions<void, Error, ExportPayload<TransactionsServiceFindAllQueryOptions>>
) {
    const { userAccess } = useUserAccess()
    const { balancesService } = useServices()
    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(userAccess)
    }

    return useMutation<void, Error, ExportPayload<TransactionsServiceFindAllQueryOptions>>({
        ...options,
        mutationKey: [balancesService.url],
        mutationFn(data) {
            return balancesService.export(data, {
                params: paramsWithUserAccess
            })
        }
    })
}
