import { CallbackPage, ExtraLogoutPage, NotFoundPage } from '@/pages'
import { NavigationRoute } from '@/providers'

export const NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS = Object.freeze({
    ROOT: '/',
    ACCOUNT: {
        LOGIN: '/account/login',
        ERROR: '/account/error'
    },
    HOME: '/home',
    PAYMENTS: {
        PAYMENT_ORDERS: '/payments/payment-orders',
        PAYMENT_FILES: '/payments/payment-files',
        EXPECTED_PAYMENTS: '/payments/expected-payments',
        INCOMING_PAYMENTS: '/payments/incoming-payments',
        INQUIRIES: '/payments/inquiries',
        RETURNS: '/payments/returns',
        RETURN_REQUESTS: '/payments/return-requests',
        PAYMENT_CAPTURES: '/payments/payment-captures'
    },
    ACCOUNTS: {
        CONNECTED_ACCOUNTS: '/accounts/connected-accounts',
        TRANSACTIONS: '/accounts/transactions',
        BALANCES: '/accounts/balances'
    },
    COUNTERPARTIES: {
        COUNTERPARTY_ACCOUNTS: '/counterparties/counterparty-accounts',
        VIRTUAL_ACCOUNTS: '/counterparties/virtual-accounts',
        DIRECT_DEBIT_MANDATES: '/counterparties/direct-debit-mandates'
    },
    FILES: '/files',
    DEVELOPERS: {
        API_KEYS: '/developers/api-keys',
        WEB_HOOKS: '/developers/webhooks',
        EVENTS: '/developers/events'
    },
    SETTINGS: {
        USERS: '/settings/users',
        ROLES: '/settings/roles',
        AUDIT_TRAIL: '/settings/audit-trail',
        CUSTOM_FIELDS: '/settings/custom-fields',
        RECONCILIATION_RULES: '/settings/reconciliation-rules',
        PAYMENT_RETRY_RULES: '/settings/payment-retry-rules'
    },
    CALLBACK: '/login-callback',
    INITIAL_CALLBACK: '/initial-login-callback',
    EXTRA_LOGOUT: '/extra-logout',
    DEBUG: '/debug',
    HELP_CENTER: '/help-center',
    UNAUTHORIZED: '/unauthorized',
    NOT_FOUND: '/not-found'
})
export const NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS = (() => {
    const dynamicPathSegments = {
        UUID: ':uuid',
        // ---
        EDIT: ':uuid/edit',
        DENY: ':uuid/deny',
        DETAILS: ':uuid/details',
        CANCEL: ':uuid/cancel',
        REJECT: ':uuid/reject',
        RETURN: ':uuid/return',
        EVENTS: ':uuid/events',
        RECONCILE: ':uuid/reconcile'
    }
    const staticPathSegmentsBySection = {
        INVITE: 'invite',
        NEW: 'new',
        UPLOAD: 'upload',
        IMPORT: 'import',
        PAYMENTS: {
            EDIT: 'edit',
            DENY: 'deny',
            CANCEL: 'cancel',
            REJECT: 'reject',
            RETURN: 'return',
            EVENTS: 'events',
            DETAILS: 'details',
            COUNTERPARTIES: 'counterparties',
            RETRIES: 'retries',
            RECONCILIATIONS: 'reconciliations',
            AUDIT_TRAIL: 'audit-trail'
        },
        ACCOUNTS: {
            RECONCILE: 'reconcile',
            EDIT: 'edit',
            DETAILS: 'details',
            VIRTUAL_ACCOUNTS: 'virtual-accounts',
            EVENTS: 'events',
            RECONCILIATIONS: 'reconciliations',
            AUDIT_TRAIL: 'audit-trail'
        },
        COUNTERPARTIES: {
            EDIT: 'edit',
            DETAILS: 'details',
            DENY: 'deny',
            COUNTERPARTY_ACCOUNTS: 'counterparty-accounts',
            VIRTUAL_ACCOUNTS: 'virtual-accounts',
            COUNTERPARTIES: 'counterparty',
            VERIFICATIONS: 'verifications',
            EVENTS: 'events',
            AUDIT_TRAIL: 'audit-trail'
        },
        FILES: {
            EVENTS: 'events',
            DETAILS: 'details',
            AUDIT_TRAIL: 'audit-trail'
        },
        DEVELOPERS: {
            EDIT: 'edit',
            DETAILS: 'details',
            AUDIT_TRAIL: 'audit-trail'
        },
        SETTINGS: {
            DETAILS: 'details',
            EDIT: 'edit',
            AUDIT_TRAIL: 'audit-trail',
            HISTORY: 'history'
        }
    }

    return {
        ...dynamicPathSegments,
        ...staticPathSegmentsBySection
    }
})()

export const CallbackRoute: NavigationRoute = {
    title: 'Callback',
    path: '/login-callback',
    element: <CallbackPage isInitialLogin={false} />
}

export const InitialCallbackRoute: NavigationRoute = {
    title: 'Callback',
    path: '/initial-login-callback',
    element: <CallbackPage isInitialLogin={true} />
}

export const ExtraLogoutRoute: NavigationRoute = {
    title: 'app.common.account.logout.title',
    path: 'extra-logout',
    element: <ExtraLogoutPage />
}

export const NotFoundRoute: NavigationRoute = {
    title: 'app.common.not_found.title',
    path: '*',
    element: <NotFoundPage />
}
