import { SearchIcon } from '@/components/@icons'
import { TextInput } from '@/components/@inputs'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { TableHeaderSearchFormProps } from './TableHeaderSearch.types'

const TABLE_HEADER_SEARCH_FORM_INPUT_WIDTHS = ['180px', null, null, null, '380px', '560px']

export const TableHeaderSearch: React.FC<TableHeaderSearchFormProps> = ({ objectType }) => {
    const intl = useIntl()
    const customPlaceholderValues = useMemo(() => {
        if (!objectType) {
            return
        }

        const messageId = `api.object.${objectType}`

        return {
            objectType: intl.formatMessage({ id: messageId, defaultMessage: objectType }).toLowerCase()
        }
    }, [objectType, intl])

    return (
        <TextInput
            name="search"
            customPlaceholderKey="app.common.form.input.search.placeholder"
            customPlaceholderValues={customPlaceholderValues}
            inputLeftElement={<SearchIcon />}
            isClearIconDisplayed={true}
            isLabelDisplayed={false}
            width={TABLE_HEADER_SEARCH_FORM_INPUT_WIDTHS}
            marginBottom={0}
        />
    )
}
