import { AlertMessage, AlertMessageType, FormToggleOptionals } from '@/components'
import { CountryInput, TextInput } from '@/components/@inputs'
import { Flex, SlideFade } from '@chakra-ui/react'
import { useCallback, useMemo, useState } from 'react'

interface OptionalAccountHolderAddressProps {
    sectionName?: string
    isDisabled?: boolean
}

export const OptionalAccountHolderAddress: React.FC<OptionalAccountHolderAddressProps> = ({
    sectionName,
    isDisabled
}) => {
    const [showAddressForm, setShowAddressForm] = useState<boolean>(false)
    const onToggleOptionalFields = useCallback(() => {
        setShowAddressForm((prevState) => {
            return !prevState
        })
    }, [])
    const inputNamePrefix = useMemo(
        () => (sectionName ? `${sectionName}.holder_address` : 'holder_address'),
        [sectionName]
    )

    return (
        <>
            <SlideFade in={showAddressForm} unmountOnExit={true}>
                <Flex width="100%" direction="column" gap="16px">
                    <AlertMessage
                        isClosable={false}
                        type={AlertMessageType.INFO}
                        descriptionKey="app.common.form.alert.holder_address.description"
                    />
                    <CountryInput
                        name={`${inputNamePrefix}.country`}
                        data-testid="holder_address.country"
                        customPlaceholderKey="app.common.form.input.holder_address.country.placeholder"
                        isPlaceholderDisplayed={true}
                        isDisabled={isDisabled}
                    />
                    <TextInput name={`${inputNamePrefix}.city`} isDisabled={isDisabled} />
                    <TextInput name={`${inputNamePrefix}.building_number`} isDisabled={isDisabled} />
                    <TextInput name={`${inputNamePrefix}.street_name`} isDisabled={isDisabled} />
                    <TextInput name={`${inputNamePrefix}.postal_code`} isDisabled={isDisabled} />
                    <TextInput name={`${inputNamePrefix}.region_state`} isDisabled={isDisabled} />
                </Flex>
            </SlideFade>
            <FormToggleOptionals
                labelId="app.common.form.input.holder_address.toggle_optional_props.label"
                onClick={onToggleOptionalFields}
                condition={showAddressForm}
            />
        </>
    )
}
