import { CONNECTED_ACCOUNT_SERVICE_NAME_TO_PAYMENT_PROPERTIES } from '@/services'
import {
    ConnectedAccount,
    ConnectedAccountSchema,
    ConnectedAccountTypeSchema,
    DirectionSchema,
    PaymentOrderTypeSchema,
    ServiceName,
    ServiceNameSchema
} from '@webapps/numeral-ui-core'
import { chain, defaultTo, isEmpty } from 'lodash'

export function isFinancialInstitutionConnectedAccount(account?: ConnectedAccount): boolean {
    return account?.type === ConnectedAccountTypeSchema.enum.financial_institution
}

export function isCorporateConnectedAccount(account?: ConnectedAccount): boolean {
    return account?.type === ConnectedAccountTypeSchema.enum.corporate
}

export function enabledConnectedAccountsFilter(account: ConnectedAccount): boolean {
    const isDisabled = globalThis.Boolean(account?.disabled_at)
    return !isDisabled
}

export function fileIngestionServiceConnectedAccountsFilter(serviceName: ServiceName): boolean {
    return serviceName === ServiceNameSchema.enum.file_ingestion
}

/**
 * @todo
 * Add test
 */
export function corporateServicesConnectedAccountsFilter(serviceName: ServiceName): boolean {
    switch (serviceName) {
        case ServiceNameSchema.enum.sct:
        case ServiceNameSchema.enum.sdd:
        case ServiceNameSchema.enum.sct_inst:
        case ServiceNameSchema.enum.sct_urgent:
        case ServiceNameSchema.enum.target2:
        case ServiceNameSchema.enum.fps:
        case ServiceNameSchema.enum.bacs_direct_credit:
        case ServiceNameSchema.enum.chaps:
        case ServiceNameSchema.enum.treasury_local:
        case ServiceNameSchema.enum.treasury_relocated:
        case ServiceNameSchema.enum.treasury_international:
        case ServiceNameSchema.enum.treasury_safeguarding:
        case ServiceNameSchema.enum.swift:
        case ServiceNameSchema.enum.local_iceland: {
            return true
        }

        default: {
            return false
        }
    }
}

export function createPaymentOrderConnectedAccountServicesFilter(account: ConnectedAccount): boolean {
    const activatedServices = account?.services_activated

    if (!ConnectedAccountSchema.safeParse(account) || isEmpty(activatedServices)) {
        return false
    }

    return activatedServices.filter(globalThis.Boolean).some(corporateServicesConnectedAccountsFilter)
}

export function computePaymentPropertiesByServiceName(serviceName?: ServiceName) {
    if (!serviceName) {
        return {
            direction: DirectionSchema.enum.credit,
            type: PaymentOrderTypeSchema.enum.sepa
        }
    }

    return chain(CONNECTED_ACCOUNT_SERVICE_NAME_TO_PAYMENT_PROPERTIES)
        .get(serviceName)
        .thru((result) => defaultTo(result, Object.create(null)))
        .value()
}
