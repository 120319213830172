import { UserInfo } from '@webapps/numeral-ui-core'
import { omit, some } from 'lodash'

export function hasUserMatchingRole(role: string, roles: string[]): boolean {
    return roles?.includes(role)
}

export function isUserInfoIncomplete(userInfo: UserInfo): boolean {
    const optionalKey = 'email'
    return some(omit(userInfo, optionalKey), (value) => value === '')
}
