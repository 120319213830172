import { useServices, useUserAccess } from '@/hooks'
import { filterUserAccessQueryParams } from '@/services'
import { MutationOptions, useMutation } from '@tanstack/react-query'
import { ExportPayload, ReturnRequestsServiceFindAllQueryOptions } from '@webapps/numeral-ui-core'

export function useMutationExportReturnRequests(
    options?: MutationOptions<void, Error, ExportPayload<ReturnRequestsServiceFindAllQueryOptions>>
) {
    const { userAccess } = useUserAccess()
    const { returnRequestsService } = useServices()
    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(userAccess)
    }

    return useMutation<void, Error, ExportPayload<ReturnRequestsServiceFindAllQueryOptions>>({
        ...options,
        mutationKey: [returnRequestsService.url],
        mutationFn(data) {
            return returnRequestsService.export(data, {
                params: paramsWithUserAccess
            })
        }
    })
}
