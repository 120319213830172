import { CURRENCIES, DEFAULT_CURRENCY_CODE, DEFAULT_DATE_TEMPLATE_FORMAT } from '@/constants'
import {
    createPaymentOrderConnectedAccountServicesFilter,
    enabledConnectedAccountsFilter,
    isFinancialInstitutionConnectedAccount
} from '@/services/ConnectedAccounts'
import { combineFilters } from '@/utils'
import {
    ConnectedAccount,
    CurrencyCode,
    DirectDebitMandate,
    DirectDebitMandateSequenceSchema,
    DirectDebitMandateTypeSchema,
    Direction,
    DirectionSchema,
    PaymentOrder,
    PaymentOrderStatusSchema,
    PaymentOrderType,
    PaymentOrderTypeSchema,
    ReturnRequestReturnReasonSchema,
    SepaReturnReasonSchema
} from '@webapps/numeral-ui-core'
import { format } from 'date-fns'
import { isEmpty } from 'lodash'

export function isSDDPayment(type?: PaymentOrderType, direction?: Direction): boolean {
    return type === PaymentOrderTypeSchema.enum.sepa && direction === DirectionSchema.enum.debit
}

export function isIcelandicExpPayment(type?: PaymentOrderType): boolean {
    return type === PaymentOrderTypeSchema.enum.icelandic_exp
}

export function isSCTPayment(type?: PaymentOrderType, direction?: Direction): boolean {
    return type === PaymentOrderTypeSchema.enum.sepa && direction === DirectionSchema.enum.credit
}

export function isSCTInstPayment(type?: PaymentOrderType, direction?: Direction): boolean {
    return type === PaymentOrderTypeSchema.enum.sepa_instant && direction === DirectionSchema.enum.credit
}

export function isTreasuryPayment(type?: PaymentOrderType): boolean {
    return type === PaymentOrderTypeSchema.enum.treasury
}

export function isSwiftPayment(type?: PaymentOrderType): boolean {
    return type === PaymentOrderTypeSchema.enum.swift
}

export function hasUltimateOriginator(item?: unknown) {
    return (
        !isEmpty((item as PaymentOrder)?.ultimate_originator?.holder_name) ||
        !isEmpty((item as PaymentOrder)?.ultimate_originator?.organization_identification?.bank_code) ||
        !isEmpty((item as PaymentOrder)?.ultimate_originator?.organization_identification?.other)
    )
}

export function isPaymentOrderReturnAvailable(item?: PaymentOrder, connectedAccount?: ConnectedAccount): boolean {
    const hasStatusExecuted = item?.status === PaymentOrderStatusSchema.enum.executed
    const hasFinancialInstitutionAccount = isFinancialInstitutionConnectedAccount(connectedAccount)

    return hasStatusExecuted && hasFinancialInstitutionAccount
}

export function getPaymentOrderReturnReasonOptions(item?: PaymentOrder, connectedAccount?: ConnectedAccount) {
    switch (true) {
        case isSDDPayment(item?.type, item?.direction) && isPaymentOrderReturnAvailable(item, connectedAccount): {
            return [
                SepaReturnReasonSchema.enum.AM05,
                SepaReturnReasonSchema.enum.MS02,
                SepaReturnReasonSchema.enum.MS03
            ]
        }

        default: {
            return ReturnRequestReturnReasonSchema.options
        }
    }
}

export function computePaymentOrderCurrency(paymentOrderType?: PaymentOrderType): CurrencyCode {
    switch (paymentOrderType) {
        case PaymentOrderTypeSchema.enum.fps:
        case PaymentOrderTypeSchema.enum.bacs: {
            return CURRENCIES.GBP.code
        }
        case PaymentOrderTypeSchema.enum.chaps: {
            return CURRENCIES.GBP.code
        }

        case PaymentOrderTypeSchema.enum.sepa:
        case PaymentOrderTypeSchema.enum.sepa_instant: {
            return CURRENCIES.EUR.code
        }

        case PaymentOrderTypeSchema.enum.icelandic_exp: {
            return CURRENCIES.ISK.code
        }

        default: {
            return DEFAULT_CURRENCY_CODE
        }
    }
}

export function isPaymentOrderMandateAvailable(type?: PaymentOrderType, direction?: Direction): boolean {
    return type === PaymentOrderTypeSchema.enum.sepa && direction === DirectionSchema.enum.debit
}

/**
 * Generate a partial direct debit mandate with default properties, used for prefilling payment order forms
 */
export function generatePaymentOrderDefaultDirectDebitMandate(
    type?: PaymentOrderType,
    direction?: Direction
): Pick<DirectDebitMandate, 'reference' | 'signature_date' | 'type' | 'sequence'> | undefined {
    if (!isPaymentOrderMandateAvailable(type, direction)) {
        return
    }

    return {
        reference: '',
        signature_date: format(new Date(), DEFAULT_DATE_TEMPLATE_FORMAT),
        type: DirectDebitMandateTypeSchema.enum.sepa_core,
        sequence: DirectDebitMandateSequenceSchema.enum.one_off
    }
}

export const createPaymentOrderConnectedAccountsCombinedFilter = combineFilters<ConnectedAccount>(
    enabledConnectedAccountsFilter,
    createPaymentOrderConnectedAccountServicesFilter
)
