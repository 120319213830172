import { useServices, useUserAccess } from '@/hooks'
import { UploadFileData, UploadFileResponse, filterUserAccessQueryParams } from '@/services'
import { APIError } from '@/types'
import { MutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { noop } from 'lodash'

export function useMutationUploadFile(
    options?: MutationOptions<UploadFileResponse, AxiosError<APIError<UploadFileResponse>>, UploadFileData>
) {
    const { userAccess } = useUserAccess()
    const { uploaderService } = useServices()
    const queryClient = useQueryClient()
    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(userAccess)
    }

    return useMutation<UploadFileResponse, AxiosError<APIError<UploadFileResponse>>, UploadFileData>({
        ...options,
        mutationKey: [uploaderService.url],
        mutationFn(data) {
            return uploaderService?.uploadFile(data, {
                params: paramsWithUserAccess
            })
        },
        async onSuccess(...args) {
            options?.onSuccess?.(...args)

            await queryClient
                .invalidateQueries({
                    queryKey: [uploaderService.url]
                })
                .catch(noop)
        }
    })
}
