import { useActivatedFeatures, usePermissions, useServices, useUserAccess } from '@/hooks'
import { ENTITY_FEATURE, filterUserAccessQueryParams, PERMISSION } from '@/services'
import { type UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query'
import { Inquiry, Uuid } from '@webapps/numeral-ui-core'

export function useQueryFindByIdInquiry(id?: Uuid, options?: Partial<UndefinedInitialDataOptions<Inquiry, Error>>) {
    const { inquiriesService } = useServices()
    const { userAccess } = useUserAccess()
    const { isFeatureActivated } = useActivatedFeatures()
    const { hasPermission } = usePermissions()

    const isQueryEnabled =
        isFeatureActivated(ENTITY_FEATURE.INQUIRY) &&
        hasPermission(PERMISSION.PAYMENTS_VIEW_INQUIRIES) &&
        options?.enabled
    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(userAccess)
    }

    return useQuery<Inquiry, Error>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [inquiriesService.url, { id, ...paramsWithUserAccess }],
        queryFn({ signal }) {
            return inquiriesService.findById(id as string, { signal, params: paramsWithUserAccess })
        }
    })
}
