import { onCLS, onFCP, onLCP, onTTFB } from 'web-vitals'

export function reportWebVitals(isEnabled: boolean) {
    const logging = globalThis.console.log

    if (!isEnabled) {
        return
    }

    onCLS(logging)
    onFCP(logging)
    onLCP(logging)
    onTTFB(logging)
}
