import { QueryClientConfig } from '@tanstack/react-query'

export const queryClientConfig: QueryClientConfig = {
    defaultOptions: {
        queries: {
            enabled: false,
            retry: false
        }
    }
}
