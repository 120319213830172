import { useServices, useUserAccess } from '@/hooks'
import { filterUserAccessQueryParams } from '@/services'
import { MutationOptions, useMutation } from '@tanstack/react-query'
import { CounterpartyAccountsServiceFindAllQueryOptions, ExportPayload } from '@webapps/numeral-ui-core'

export function useMutationExportCounterpartyAccounts(
    options?: MutationOptions<void, Error, ExportPayload<CounterpartyAccountsServiceFindAllQueryOptions>>
) {
    const { userAccess } = useUserAccess()
    const { counterpartyAccountsService } = useServices()
    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(userAccess)
    }

    return useMutation<void, Error, ExportPayload<CounterpartyAccountsServiceFindAllQueryOptions>>({
        ...options,
        mutationKey: [counterpartyAccountsService.url],
        mutationFn(data) {
            return counterpartyAccountsService.export(data, {
                params: paramsWithUserAccess
            })
        }
    })
}
