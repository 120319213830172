import { ErrorBoundary } from '@/components'
import { If } from '@/components/@misc'
import { queryClientConfig } from '@/configuration'
import { useDeviceDetector, useLocaleSwitcher } from '@/hooks'
import { UnsupportedDevicePage } from '@/pages'
import {
    AuthProvider,
    ConfigurationProvider,
    NavigationRoutesProvider,
    PageProvider,
    ServicesProvider,
    ToastsProvider,
    UserAccessProvider
} from '@/providers'
import { CUSTOM_CHAKRA_THEME } from '@/theme'
import { ChakraProvider } from '@chakra-ui/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { noop } from 'lodash'
import { IntlProvider } from 'react-intl'
import { BrowserRouter as Router } from 'react-router-dom'
import { AnalyticsHandler } from './AnalyticsHandler'
import { AppRoutes } from './AppRoutes'
import { ScrollToTop } from './ScrollToTop'

export const AppWithProviders: React.FC = () => {
    const { isMobileBrowser } = useDeviceDetector()
    const { locale, messages } = useLocaleSwitcher()
    const queryClient = new QueryClient(queryClientConfig)

    return (
        <IntlProvider locale={locale} messages={messages} onError={noop}>
            <ChakraProvider theme={CUSTOM_CHAKRA_THEME}>
                <If condition={isMobileBrowser}>
                    <UnsupportedDevicePage />
                </If>
                <If condition={!isMobileBrowser}>
                    <Router>
                        <ErrorBoundary>
                            <ScrollToTop />
                            <ConfigurationProvider>
                                <QueryClientProvider client={queryClient}>
                                    <NavigationRoutesProvider>
                                        <ServicesProvider>
                                            <AuthProvider>
                                                <UserAccessProvider>
                                                    <ToastsProvider>
                                                        <AnalyticsHandler />
                                                        <PageProvider>
                                                            <AppRoutes />
                                                        </PageProvider>
                                                    </ToastsProvider>
                                                </UserAccessProvider>
                                            </AuthProvider>
                                        </ServicesProvider>
                                    </NavigationRoutesProvider>
                                </QueryClientProvider>
                            </ConfigurationProvider>
                        </ErrorBoundary>
                    </Router>
                </If>
            </ChakraProvider>
        </IntlProvider>
    )
}
