import { FilesIcon, HomeIcon } from '@/components/@icons'
import { ENVIRONMENT_VARIABLES } from '@/configuration'
import { MainLayout, NestedTabsLayout } from '@/layouts'
import { DebugPage, HomePage } from '@/pages'
import { chain, get } from 'lodash'
import { Navigate } from 'react-router-dom'
import {
    getAccountNavigationRoutes,
    getAccountsNavigationRoutes,
    getCounterpartiesNavigationRoutes,
    getDevelopersNavigationRoutes,
    getPaymentsNavigationRoutes,
    getSettingsNavigationRoutes
} from './@utils'
import { getFilesNavigationRoutes } from './@utils/getFilesNavigationRoutes'
import {
    CallbackRoute,
    ExtraLogoutRoute,
    InitialCallbackRoute,
    NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS,
    NotFoundRoute
} from './NavigationRoutesProvider.const'
import { NavigationRoute } from './NavigationRoutesProvider.types'
import { withUserAccess } from '@/providers'
import { UUID_PATTERN } from '@/utils/@validators'

/**
 * @todo
 * Write unit test
 */
export const getNestedRoutesWithTabLayout = (
    path: string,
    routes: NavigationRoute[],
    props?: {
        header: JSX.Element
    }
) => {
    const generateRoutes = (path: string, routes: NavigationRoute[], element: JSX.Element) => {
        const getIndexRoutePath = get(routes, '[0].path', '/')
        const indexRouteWithRedirect = { index: true, element: <Navigate replace={true} to={getIndexRoutePath} /> }
        return [
            {
                path,
                element,
                routes: [indexRouteWithRedirect, ...routes, NotFoundRoute]
            }
        ]
    }
    return generateRoutes(path, routes, <NestedTabsLayout routes={routes} {...props} />)
}

export const getNavigationRoutes = (): NavigationRoute[] => {
    const MainLayoutWithUserAccess = withUserAccess(MainLayout)

    return [
        getAccountNavigationRoutes(),
        { index: true, element: <Navigate replace={true} to="payments" /> },
        {
            path: '/',
            element: <MainLayoutWithUserAccess />,
            routes: [
                {
                    title: 'app.home.title',
                    path: '/home',
                    element: <HomePage />,
                    icon: <HomeIcon />,
                    configuration: {
                        isDisabled: true
                    }
                },
                getPaymentsNavigationRoutes(),
                getAccountsNavigationRoutes(),
                getCounterpartiesNavigationRoutes(),
                getFilesNavigationRoutes(),
                getDevelopersNavigationRoutes(),
                getSettingsNavigationRoutes(),
                {
                    title: 'app.debug.title',
                    path: '/debug',
                    element: <DebugPage />,
                    icon: <FilesIcon />,
                    configuration: {
                        isHidden: ENVIRONMENT_VARIABLES.PROD,
                        isFooter: true
                    }
                },
                NotFoundRoute
            ]
        },
        InitialCallbackRoute,
        CallbackRoute,
        ExtraLogoutRoute,
        NotFoundRoute
    ]
}

export function getPrivateTopLevelRoutes(routes: NavigationRoute[]): NavigationRoute[] {
    const privateTopLevelRoutesFinder = ({ path }: NavigationRoute): boolean => path === '/'
    const topLevelRoute: NavigationRoute | undefined = routes?.find(privateTopLevelRoutesFinder)

    function flatFilter(nestedProp: string, compareKey: string, compareValue: any, source?: any[]) {
        return source?.filter((sourceItem) => {
            const shouldKeep: boolean = sourceItem[compareKey] !== compareValue
            if (shouldKeep && sourceItem[nestedProp]) {
                sourceItem[nestedProp] = flatFilter(nestedProp, compareKey, compareValue, sourceItem[nestedProp])
            }
            return shouldKeep
        })
    }

    return flatFilter('routes', 'path', '*', topLevelRoute?.routes) || []
}

/**
 * @description
 * Some relative paths (/invite, /new, /edit) have to completely overwrite the parent outlet.
 */
export function shouldRenderOutlet(pathname: string, uuid = ''): boolean {
    const patternOROperator = '|'
    const { UUID } = NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS
    const pattern = chain(NAVIGATION_ROUTES_PROVIDER_RELATIVE_PATHS)
        .pick('UPLOAD', 'INVITE', 'NEW', 'EDIT', 'IMPORT', 'CANCEL', 'DENY', 'REJECT', 'RETURN', 'RECONCILE')
        .invokeMap('replace', UUID, uuid)
        .join(patternOROperator)
        .value()

    const outletRegExp = new RegExp(`(${pattern})$`, 'i')

    return !!(outletRegExp.test(pathname) || uuid)
}

/**
 * @description
 * Update anything matching {uuid}/{action} with {uuid}/{new_action}
 */
export function updateUUIDRelativeActionPathname(pathName: string, replacement: string): string {
    const pattern = new RegExp(`/(${UUID_PATTERN.source})/([^/]+)$`, 'i')
    return pathName?.replace(pattern, `/$1/${replacement}`)
}

export function navigationProviderIndexRoutesFilter({ index }: NavigationRoute): boolean {
    return !index
}

export function navigationProviderDisabledRoutesFilter(route: NavigationRoute): boolean {
    return route.path ? !route.configuration?.isDisabled : false
}

export function navigationProviderOutletExceptionRoutesFilter(route: NavigationRoute): boolean {
    return route.path ? !shouldRenderOutlet(route.path) : false
}
