import { useMutationUserInfo } from '@/hooks'
import { Modal, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react'
import { UserInfo } from '@webapps/numeral-ui-core'
import { omit } from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import { UserInfoForm } from './@components'
import { HIDDEN_USER_INFO } from './ConfirmInfoModal.const'
import { ConfirmInfoModalProps } from './ConfirmInfoModal.types'
import { getUserInfoUpdatedFields } from './ConfirmInfoModal.utils'

export const ConfirmInfoModal: React.FC<ConfirmInfoModalProps> = ({ userInfo }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const mutation = useMutationUserInfo({ onSuccess: onClose })
    const initialUserInfo = useMemo(() => omit(userInfo, HIDDEN_USER_INFO), [userInfo])
    const onUserInfoUpdate = useCallback(
        (data: Partial<UserInfo>) => {
            const updatedUserInfo = getUserInfoUpdatedFields<UserInfo>(userInfo, data)

            if (Object.keys(updatedUserInfo).length > 0) {
                mutation.mutate(updatedUserInfo)
            }
        },
        [userInfo, mutation]
    )

    useEffect(() => {
        onOpen()
    }, [onOpen])

    return (
        <Modal closeOnOverlayClick={false} size="xl" isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent padding="40px">
                <UserInfoForm data={initialUserInfo} onSubmit={onUserInfoUpdate} isLoading={mutation.isPending} />
            </ModalContent>
        </Modal>
    )
}
