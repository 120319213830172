import { useConfiguration, useNavigationRoutes, useQueryGenerateAccessToken, useServices } from '@/hooks'
import { AUTH_QUERY_PARAMS } from '@/providers'
import { getUrlParameter } from '@/utils'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

type CallbackPageProps = {
    isInitialLogin?: boolean
}

/**
 * The default behavior of the callback component is to log the user in the default env (primaryEnvironment)
 *
 * In a redirection context with double login, the user is first
 * If the login is successful we then process to redirect to the default login flow
 */
export const CallbackPage: React.FC<CallbackPageProps> = ({ isInitialLogin = true }) => {
    const intl = useIntl()
    const navigate = useNavigate()
    const { authenticationService } = useServices()
    const { apiEnvironment } = useConfiguration()
    const { paths } = useNavigationRoutes()

    const [isQueryEnabled, setIsQueryEnabled] = useState<boolean>(true)

    const urlCode = useRef<string>(getUrlParameter(AUTH_QUERY_PARAMS.CODE)).current
    const urlState = useRef<string>(getUrlParameter(AUTH_QUERY_PARAMS.STATE)).current
    const navigationOptions = useRef({ replace: true }).current

    const baseApiUrlOverride = useMemo(() => {
        if (!isInitialLogin && apiEnvironment.secondary) {
            return apiEnvironment.secondary.url
        }
    }, [isInitialLogin, apiEnvironment])

    const query = useQueryGenerateAccessToken(urlCode, urlState, baseApiUrlOverride, {
        enabled: !!urlCode && !!urlState && isQueryEnabled
    })

    useEffect(() => {
        if (query.isSuccess) {
            setIsQueryEnabled(false)

            if (isInitialLogin && apiEnvironment.secondary) {
                authenticationService.login(apiEnvironment.secondary.url)
            } else {
                navigate(paths.ROOT, navigationOptions)
            }
        }

        if (query.isError) {
            setIsQueryEnabled(false)

            navigate(paths.ACCOUNT.ERROR, {
                state: {
                    description: intl.formatMessage({ id: 'app.account.login_error.no_access_credentials.content' })
                }
            })
        }
    }, [query, apiEnvironment, authenticationService, intl, isInitialLogin, navigate, navigationOptions, paths])

    return null
}
