import { useServices, useUserAccess } from '@/hooks'
import { filterUserAccessQueryParams } from '@/services'
import { MutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { ReturnRequest, ReturnRequestCreateData } from '@webapps/numeral-ui-core'
import { noop } from 'lodash'

export function useMutationCreateReturnRequest(
    options?: MutationOptions<Partial<ReturnRequest>, Error, ReturnRequestCreateData>
) {
    const { userAccess } = useUserAccess()
    const { returnRequestsService } = useServices()
    const queryClient = useQueryClient()
    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(userAccess)
    }

    return useMutation<ReturnRequest, Error, ReturnRequestCreateData>({
        ...options,
        mutationKey: [returnRequestsService.url],
        mutationFn(data) {
            return returnRequestsService.createReturnRequest(data, {
                params: paramsWithUserAccess
            })
        },
        async onSuccess(...args) {
            options?.onSuccess?.(...args)

            await queryClient
                .invalidateQueries({
                    queryKey: [returnRequestsService.url]
                })
                .catch(noop)
        }
    })
}
