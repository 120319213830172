import { noop } from 'lodash'
import { createContext } from 'react'
import { UserAccessContextValue } from './UserAccessProvider.types'

export const UserAccessContext = createContext<UserAccessContextValue>({
    userAccess: undefined,
    activatedFeatures: undefined,
    userPermissions: undefined,
    onSetUserAccess: noop
})
