import { useEffect } from 'react'
import { useAuth } from '@/hooks'

export const LoginPage = () => {
    const { onLogin } = useAuth()

    useEffect(() => {
        onLogin()
    }, [onLogin])

    return null
}
