export interface AuthContextValue {
    onLogin: () => void
    onLogout: () => void
}

export enum AUTH_QUERY_PARAMS {
    CODE = 'code',
    STATE = 'state',
    ERROR = 'error',
    ERROR_DESCRIPTION = 'error_description'
}

export enum AUTH_ERROR_TYPES {
    UNAUTHORIZED = 'unauthorized',
    INVALID_REQUEST = 'invalid_request',
    ACCESS_DENIED = 'access_denied'
}
