import { useContext } from 'react'
import { AuthContext } from '@/providers'

export function useAuth() {
    const context = useContext(AuthContext)

    if (!context) {
        throw new Error(`Must be used in the scope of ${AuthContext.displayName}`)
    }

    return context
}
