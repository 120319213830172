import {
    EmptyContent,
    EmptyContentProps,
    LoadingSpinner,
    RestrictedSection,
    Table,
    TableHeader,
    useTableRowNavigation
} from '@/components'
import { useNavigationRoutes, usePermissions, useQueryFindAllPaymentFiles, useQueryParams } from '@/hooks'
import { PageHeaderLayout, PageLayout } from '@/layouts'
import { QuickFilterProvider } from '@/providers'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { ENTITY_FEATURE, PERMISSION } from '@/services'
import { queryDataAggregation } from '@/utils'
import { PaymentFile, PaymentFilesServiceFindAllQueryOptions } from '@webapps/numeral-ui-core'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { PaymentFileCustomColumns } from './PaymentFile.type'
import {
    PAYMENT_FILE_FILTERS,
    PAYMENT_FILE_TABLE_COLUMN_CUSTOMIZERS,
    PAYMENT_FILE_TABLE_STATIC_STATE
} from './PaymentFiles.page.const'

export const PaymentFilesPage: React.FC = () => {
    const intl = useIntl()
    const navigate = useNavigate()
    const { paths, relativePaths } = useNavigationRoutes()
    const { hasPermission } = usePermissions()
    const { search, filterBy } = useQueryParams<PaymentFilesServiceFindAllQueryOptions>('search', {
        filterBy: { configuration: { filters: PAYMENT_FILE_FILTERS } }
    })

    const title = useMemo<string>(() => intl.formatMessage({ id: 'app.payments.payment_files.title' }), [intl])
    const query = useQueryFindAllPaymentFiles({ ...search.parsedState, ...filterBy.parsedState })
    const data = useMemo(() => queryDataAggregation(query.data), [query])
    const isDataEmpty = useMemo<boolean>(() => isEmpty(data), [data])
    const emptyContentProps = useMemo<EmptyContentProps | undefined>(() => {
        if (!isDataEmpty) {
            return
        }

        if (search.isActive || filterBy.isActive) {
            return {
                title: intl.formatMessage({ id: 'app.payments.payment_files.empty_content.filter.title' }),
                content: intl.formatMessage({ id: 'app.table.filter.empty_content.subtitle' })
            }
        } else {
            return {
                title: intl.formatMessage({ id: 'app.payments.payment_files.empty_content.title' }),
                content: intl.formatMessage({ id: 'app.payments.payment_files.empty_content.subtitle' })
            }
        }
    }, [filterBy.isActive, intl, isDataEmpty, search.isActive])

    const actions = useMemo(() => {
        if (!hasPermission(PERMISSION.PAYMENTS_CREATE_PAYMENT_ORDERS)) {
            return
        }

        return [
            {
                onClick() {
                    navigate(`${paths.PAYMENTS.PAYMENT_ORDERS}/${relativePaths.UPLOAD}`)
                },
                children: intl.formatMessage({ id: 'app.payments.payment_files.actions.upload.title' })
            }
        ]
    }, [hasPermission, intl, navigate, paths.PAYMENTS.PAYMENT_ORDERS, relativePaths.UPLOAD])

    const onTableRowClick = useTableRowNavigation<PaymentFile>('id', relativePaths.PAYMENTS.DETAILS)

    return (
        <PageLayout>
            <PageHeaderLayout title={title} showBackButton={false} />
            <RestrictedSection feature={ENTITY_FEATURE.PAYMENT_ORDER} permission={PERMISSION.FILES_VIEW_FILES}>
                <QuickFilterProvider<PaymentFilesServiceFindAllQueryOptions> filterBy={filterBy}>
                    <TableHeader<PaymentFilesServiceFindAllQueryOptions>
                        objectType={ApiObjectTypeExtendedSchema.enum.payment_file}
                        isLoading={query.isLoading}
                        filterBy={filterBy}
                        search={search}
                        actions={actions}
                    />
                    {(() => {
                        switch (true) {
                            case query.isLoading: {
                                return <LoadingSpinner />
                            }

                            case query.isError || isDataEmpty: {
                                return <EmptyContent {...emptyContentProps} />
                            }

                            default: {
                                return (
                                    <Table<PaymentFile, PaymentFileCustomColumns>
                                        data={data}
                                        onRowClick={onTableRowClick}
                                        onScrollToBottom={query.fetchNextPage}
                                        isLoading={query.hasNextPage}
                                        state={PAYMENT_FILE_TABLE_STATIC_STATE}
                                        columnCustomizers={PAYMENT_FILE_TABLE_COLUMN_CUSTOMIZERS}
                                    />
                                )
                            }
                        }
                    })()}
                </QuickFilterProvider>
            </RestrictedSection>
        </PageLayout>
    )
}
