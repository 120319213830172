import { useActivatedFeatures, useConfiguration, usePermissions, useServices, useUserAccess } from '@/hooks'
import { ENTITY_FEATURE, filterUserAccessQueryParams, PERMISSION } from '@/services'
import { UndefinedInitialDataInfiniteOptions, useInfiniteQuery } from '@tanstack/react-query'
import { List, PaymentOrder, PaymentOrdersServiceFindAllQueryOptions } from '@webapps/numeral-ui-core'
import { paginationQueryOptions } from '../paginationQueryOptions.const'

export function useQueryFindAllPaymentOrders(
    params?: Partial<PaymentOrdersServiceFindAllQueryOptions>,
    options?: Partial<UndefinedInitialDataInfiniteOptions<List<PaymentOrder>, Error>>
) {
    const { paymentOrdersService } = useServices()
    const { pagination } = useConfiguration()
    const { userAccess } = useUserAccess()
    const { isFeatureActivated } = useActivatedFeatures()
    const { hasPermission } = usePermissions()

    const isQueryEnabled =
        isFeatureActivated(ENTITY_FEATURE.PAYMENT_ORDER) &&
        hasPermission(PERMISSION.PAYMENTS_VIEW_PAYMENT_ORDERS) &&
        options?.enabled
    const paramsWithUserAccess: typeof params = {
        limit: pagination.limit,
        ...params,
        ...filterUserAccessQueryParams(userAccess)
    }

    return useInfiniteQuery<List<PaymentOrder>, Error>({
        ...options,
        enabled: isQueryEnabled,
        initialPageParam: undefined,
        queryKey: [paymentOrdersService.url, paramsWithUserAccess],
        queryFn({ signal, pageParam }) {
            return paymentOrdersService.findAll(
                { ...paramsWithUserAccess, ...(pageParam as typeof params) },
                { signal }
            )
        },
        getNextPageParam(lastPage) {
            return paginationQueryOptions.getNextPageParam(lastPage, pagination.limit)
        }
    })
}
