import { EMPTY_VALUE_PLACEHOLDER } from '@/constants'
import { Flex } from '@chakra-ui/react'
import { CounterpartyAccountVerification, CounterpartyAccountVerificationService } from '@webapps/numeral-ui-core'
import { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import { getVerificationMessageIconFromResult } from './CounterpartyAccountVerificationResultDetails.utils'
import { Nullable } from '@/types'

type CounterpartyAccountVerificationResultDetailsProps = {
    accountVerification?: Nullable<CounterpartyAccountVerification>
    verificationService: CounterpartyAccountVerificationService
}

export const CounterpartyAccountVerificationResultDetails: React.FC<CounterpartyAccountVerificationResultDetailsProps> =
    memo(({ accountVerification, verificationService }) => {
        const verification = accountVerification?.[verificationService]
        const result = verification?.result
        const reason = verification?.details.reason
        const expectedValue = verification?.details?.expected_value
        const message = verification?.details.message

        const icon = getVerificationMessageIconFromResult(result)
        let messageKey = `app.counterparties.counterparty_accounts.tabs.verifications.${verificationService}.${result}`

        if (!accountVerification || !result) {
            return <>{EMPTY_VALUE_PLACEHOLDER} </>
        }

        if (reason) {
            messageKey += `.${reason}`
        }

        return (
            <Flex alignItems="center" gap="8px">
                {icon}
                <FormattedMessage id={messageKey} defaultMessage={message} values={{ expectedValue }} />
            </Flex>
        )
    })
