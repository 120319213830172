import { useUserAccess } from '@/hooks'
import { STORAGE_KEYS, StorageService, UserAccess } from '@/services'
import { Box } from '@chakra-ui/react'
import { find, head, isNil } from 'lodash'
import { JSXElementConstructor, PropsWithoutRef } from 'react'
import { NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS } from '../NavigationRoutesProvider'
import { withCalleeName } from '@/utils'
import { useLocation } from 'react-router-dom'

export function isFindAllUserAccessesQueryEnabled(userAccess: UserAccess | undefined, currentPath: string) {
    return (
        isNil(userAccess) &&
        currentPath !== NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS.CALLBACK &&
        currentPath !== NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS.INITIAL_CALLBACK &&
        currentPath !== NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS.EXTRA_LOGOUT &&
        currentPath !== NAVIGATION_ROUTES_PROVIDER_ABSOLUTE_PATHS.ACCOUNT.LOGIN
    )
}

export function setActiveAccessIdToStorage(value: string) {
    return StorageService.setItem(STORAGE_KEYS.ACTIVE_ACCESS_ID, value)
}

export function getActiveAccessIdFromStorage() {
    return StorageService.getItem(STORAGE_KEYS.ACTIVE_ACCESS_ID)
}

export function getUserAccessByAccessId(list?: UserAccess[]): UserAccess | undefined {
    const savedUserAccessId = getActiveAccessIdFromStorage()
    const defaultUserAccess = head(list)
    const savedActiveAccessId = find(list, ({ id }) => id === savedUserAccessId)

    if (isNil(savedActiveAccessId)) {
        return defaultUserAccess
    }

    return savedActiveAccessId
}

export function withUserAccess(Component: JSXElementConstructor<any>) {
    const name = `${withUserAccess?.name}(${Component?.name})`

    return withCalleeName(name, (props?: PropsWithoutRef<any>) => {
        const { userAccess } = useUserAccess()

        return (
            <Box hidden={!userAccess?.id}>
                <Component {...props} />
            </Box>
        )
    })
}
