import { useContext } from 'react'
import { UserAccessContext } from '@/providers'

export function useUserAccess() {
    const context = useContext(UserAccessContext)

    if (!context) {
        throw new Error(`Must be used in the scope of ${UserAccessContext.displayName}`)
    }

    return context
}
