import { useServices } from '@/hooks'
import { type UndefinedInitialDataOptions, useQuery, UseQueryOptions } from '@tanstack/react-query'

export function useQueryGenerateAccessToken(
    code: string,
    state: string,
    baseURLOverride?: string,
    options?: Partial<UndefinedInitialDataOptions<void, Error>>
) {
    const { authenticationService } = useServices()

    return useQuery<void, Error>({
        ...options,
        refetchOnReconnect: false,
        refetchOnMount: false,
        retryOnMount: false,
        queryKey: [authenticationService.url],
        queryFn({ signal }) {
            return authenticationService.generateAccessToken(code, state, { signal, baseURL: baseURLOverride })
        }
    })
}
