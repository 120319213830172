import { useActivatedFeatures, usePermissions, useServices, useUserAccess } from '@/hooks'
import { ENTITY_FEATURE, filterUserAccessQueryParams, PERMISSION } from '@/services'
import { type UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query'
import { IncomingPayment, Uuid } from '@webapps/numeral-ui-core'

export function useQueryFindByIdIncomingPayment(
    id?: Uuid,
    options?: Partial<UndefinedInitialDataOptions<IncomingPayment, Error>>
) {
    const { incomingPaymentsService } = useServices()
    const { userAccess } = useUserAccess()
    const { isFeatureActivated } = useActivatedFeatures()
    const { hasPermission } = usePermissions()

    const isQueryEnabled =
        isFeatureActivated(ENTITY_FEATURE.INCOMING_PAYMENT) &&
        hasPermission(PERMISSION.PAYMENTS_VIEW_INCOMING_PAYMENTS) &&
        options?.enabled
    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(userAccess)
    }

    return useQuery<IncomingPayment, Error>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [incomingPaymentsService.url, { id, ...paramsWithUserAccess }],
        queryFn({ signal }) {
            return incomingPaymentsService.findById(id as string, { signal, params: paramsWithUserAccess })
        }
    })
}
