import { useConfiguration, useNavigationRoutes, usePrevious, useQueryFindAllUserAccesses } from '@/hooks'
import { UserAccessContext } from '@/providers'
import { ENTITY_FEATURE, PERMISSION, UserAccess } from '@/services'
import { setTimeout } from '@/utils'
import { useQueryClient } from '@tanstack/react-query'
import { isEmpty, isEqualWith, isNil } from 'lodash'
import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { USER_ACCESS_REDIRECT_TIMEOUT } from './UserAccessProvider.const'
import {
    getUserAccessByAccessId,
    isFindAllUserAccessesQueryEnabled,
    setActiveAccessIdToStorage
} from './UserAccessProvider.utils'

export const UserAccessProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const { apiEnvironment } = useConfiguration()
    const { paths } = useNavigationRoutes()

    const [userAccess, setUserAccess] = useState<UserAccess | undefined>(undefined)
    const [activatedFeatures, setActivatedFeatures] = useState<Set<ENTITY_FEATURE> | undefined>(undefined)
    const [userPermissions, setUserPermissions] = useState<Set<PERMISSION> | undefined>(undefined)
    const prevUserAccess = usePrevious<UserAccess>(userAccess)

    const isQueryEnabled = useCallback(
        () => isFindAllUserAccessesQueryEnabled(userAccess, location.pathname),
        [userAccess, location.pathname]
    )

    const query = useQueryFindAllUserAccesses({
        enabled: isQueryEnabled()
    })

    const onSetUserAccess = (userAccess: UserAccess) => {
        if (isNil(userAccess) || isEqualWith(userAccess, prevUserAccess)) {
            return
        }

        setUserAccess(userAccess)
        setActivatedFeatures(new Set(userAccess.features))
        setUserPermissions(new Set(userAccess.permissions))
        setActiveAccessIdToStorage(userAccess.id)

        if (userAccess.environment !== prevUserAccess?.environment) {
            apiEnvironment.setActiveEnvironmentByName(userAccess.environment)
        }

        // Invalidating all queries, this will as well rebuild all services with the new base URL
        queryClient.cancelQueries()
        queryClient.invalidateQueries()

        if (!queryClient.getDefaultOptions().queries?.enabled) {
            onEnableQueries(userAccess)
        }

        if (!isNil(prevUserAccess)) {
            // Temporary solution to avoid being on a non-existing detail page when switching accesses
            navigate(paths.ROOT)
        }
    }

    const onEnableQueries = useMemo(() => {
        return (userAccess: UserAccess) => {
            queryClient.setDefaultOptions({
                ...queryClient.getDefaultOptions(),
                queries: { ...queryClient.getDefaultOptions().queries, enabled: Boolean(userAccess?.id) }
            })
        }
    }, [queryClient])

    const onSetDefaultUserAccess = useRef((userAccesses?: UserAccess[]) => {
        const userAccess = getUserAccessByAccessId(userAccesses)

        if (isNil(userAccess)) {
            return
        }

        onSetUserAccess(userAccess)
    }).current

    /**
     * @description
     * If not already set, get the default entry for a user or equivalent call.
     */
    useEffect(() => {
        const hasUserAccess = !isNil(userAccess)
        const hasNoUserAccesses = isEmpty(query.data)
        let cancelNavigation: CallableFunction | undefined

        if (query.isError || hasUserAccess) {
            return
        }

        if (query.isSuccess && hasNoUserAccesses) {
            cancelNavigation = setTimeout(() => {
                navigate(`${paths.ACCOUNT.ERROR}/?error_type=no_accesses`)
            }, USER_ACCESS_REDIRECT_TIMEOUT)
            return
        }

        onSetDefaultUserAccess(query.data)

        return () => cancelNavigation?.()
    }, [query, paths, userAccess, navigate, onSetDefaultUserAccess])

    return (
        <UserAccessContext.Provider value={{ userAccess, activatedFeatures, userPermissions, onSetUserAccess }}>
            {children}
        </UserAccessContext.Provider>
    )
}
