import { PERMISSION } from '@/services'
import { useCallback } from 'react'
import { useUserAccess } from '../context'
import { getGroupAdminPermissionName } from './usePermissions.utils'

export const usePermissions = () => {
    const { userPermissions } = useUserAccess()

    const hasPermission = useCallback(
        (permission: PERMISSION): boolean =>
            !!userPermissions?.has(permission) || !!userPermissions?.has(getGroupAdminPermissionName(permission)),
        [userPermissions]
    )

    return { hasPermission }
}
