import { useActivatedFeatures, usePermissions, useServices, useUserAccess } from '@/hooks'
import { ENTITY_FEATURE, PERMISSION, filterUserAccessQueryParams } from '@/services'
import { useQuery, type UndefinedInitialDataOptions } from '@tanstack/react-query'
import { PaymentFile, Uuid } from '@webapps/numeral-ui-core'

export function useQueryFindByIdPaymentFile(
    id?: Uuid,
    options?: Partial<UndefinedInitialDataOptions<PaymentFile, Error>>
) {
    const { paymentFilesService } = useServices()
    const { userAccess } = useUserAccess()
    const { isFeatureActivated } = useActivatedFeatures()
    const { hasPermission } = usePermissions()

    const isQueryEnabled =
        options?.enabled &&
        isFeatureActivated(ENTITY_FEATURE.PAYMENT_ORDER) &&
        hasPermission(PERMISSION.FILES_VIEW_FILES)

    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(userAccess)
    }

    return useQuery<PaymentFile, Error>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [paymentFilesService.url, { id, ...paramsWithUserAccess }],
        queryFn({ signal }) {
            return paymentFilesService.findById(id as string, { signal, params: paramsWithUserAccess })
        }
    })
}
