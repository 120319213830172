import { useServices, useUserAccess } from '@/hooks'
import { filterUserAccessQueryParams } from '@/services'
import { markedFieldsTransform } from '@/services/@transformRequests'
import { addTransformRequestsToHttpClientInstance } from '@/services/@transformRequests/transformRequests.utils'
import { APIError } from '@/types'
import { MutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { PaymentOrder } from '@webapps/numeral-ui-core'
import { AxiosError } from 'axios'
import { noop } from 'lodash'

export function useMutationCreatePaymentOrder(
    options?: MutationOptions<Partial<PaymentOrder> | void, AxiosError<APIError<PaymentOrder>>, PaymentOrder>
) {
    const { userAccess } = useUserAccess()
    const { paymentOrdersService } = useServices()
    const queryClient = useQueryClient()
    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(userAccess)
    }

    return useMutation<PaymentOrder | void, AxiosError<APIError<PaymentOrder>>, PaymentOrder>({
        ...options,
        mutationKey: [paymentOrdersService.url],
        mutationFn(data) {
            return paymentOrdersService.createPaymentOrder(data, {
                params: paramsWithUserAccess,
                transformRequest: addTransformRequestsToHttpClientInstance(paymentOrdersService, markedFieldsTransform)
            })
        },
        async onSuccess(...args) {
            options?.onSuccess?.(...args)

            await queryClient
                .invalidateQueries({
                    queryKey: [paymentOrdersService.url]
                })
                .catch(noop)
        }
    })
}
