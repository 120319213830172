import { usePermissions, useServices, useUserAccess } from '@/hooks'
import { filterUserAccessQueryParams, PERMISSION } from '@/services'
import { type UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query'
import { NumeralFile, Uuid } from '@webapps/numeral-ui-core'

export function useQueryFindByIdFile(id?: Uuid, options?: Partial<UndefinedInitialDataOptions<NumeralFile, Error>>) {
    const { filesService } = useServices()
    const { userAccess } = useUserAccess()
    const { hasPermission } = usePermissions()

    const isQueryEnabled = hasPermission(PERMISSION.FILES_VIEW_FILES) && options?.enabled
    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(userAccess)
    }

    return useQuery<NumeralFile, Error>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [filesService.url, { id, ...paramsWithUserAccess }],
        queryFn({ signal }) {
            return filesService.findById(id as string, { signal, params: paramsWithUserAccess })
        }
    })
}
