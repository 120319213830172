import { SupportedApiEnvironmentName } from '@/configuration'
import { useUserAccess } from '@/hooks'
import { UserAccess } from '@/services'
import { Box, BoxProps } from '@chakra-ui/react'
import classNames from 'classnames'
import { chain } from 'lodash'
import { useMemo } from 'react'

export const MainLayoutEnvironmentOverlay: React.FC<{ isCollapsed: boolean }> = ({ isCollapsed }) => {
    const { userAccess } = useUserAccess()
    const isSandbox = useMemo<boolean>(
        () => userAccess?.environment === SupportedApiEnvironmentName.SANDBOX,
        [userAccess]
    )
    const propsBox = useMemo<BoxProps>(() => {
        const environmentWrapper = chain<UserAccess>(userAccess).get('environment')
        const environmentTitle = environmentWrapper.capitalize().value()
        const environmentId = environmentWrapper.value()
        const className = classNames('MainLayout-EnvironmentOverlay', {
            Collapsed: isCollapsed
        })

        return {
            className,
            title: environmentTitle,
            'data-title': environmentTitle,
            'data-testid': environmentId
        }
    }, [isCollapsed, userAccess])

    if (!isSandbox) {
        return null
    }

    return <Box {...propsBox} />
}
