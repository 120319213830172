import { ENTITY_FEATURE } from '@/services'
import { useCallback } from 'react'
import { useUserAccess } from '../context'

export const useActivatedFeatures = () => {
    const { activatedFeatures } = useUserAccess()

    const isFeatureActivated = useCallback(
        (feature: ENTITY_FEATURE): boolean => !!activatedFeatures?.has(feature),
        [activatedFeatures]
    )

    return { isFeatureActivated }
}
