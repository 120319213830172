import { useActivatedFeatures, usePermissions, useServices, useUserAccess } from '@/hooks'
import { ENTITY_FEATURE, filterUserAccessQueryParams, PERMISSION } from '@/services'
import { Nullable } from '@/types'
import { useQuery } from '@tanstack/react-query'
import type { UndefinedInitialDataOptions } from '@tanstack/react-query/src/queryOptions'
import { ReconciliationRule, Uuid } from '@webapps/numeral-ui-core'

export function useQueryFindByIdReconciliationRule(
    id?: Nullable<Uuid>,
    options?: Partial<UndefinedInitialDataOptions<ReconciliationRule, Error>>
) {
    const { reconciliationRulesService } = useServices()
    const { userAccess } = useUserAccess()
    const { isFeatureActivated } = useActivatedFeatures()
    const { hasPermission } = usePermissions()

    const isQueryEnabled =
        isFeatureActivated(ENTITY_FEATURE.RECONCILIATION) &&
        hasPermission(PERMISSION.SETTINGS_VIEW_RECONCILIATION_RULES) &&
        options?.enabled

    const paramsWithUserAccess = {
        ...filterUserAccessQueryParams(userAccess)
    }

    return useQuery<ReconciliationRule, Error>({
        ...options,
        enabled: isQueryEnabled,
        queryKey: [reconciliationRulesService.url, { id }],
        queryFn({ signal }) {
            return reconciliationRulesService.findById(id as string, { signal, params: paramsWithUserAccess })
        }
    })
}
