import {
    AccountHolderAddress,
    CounterpartyAccount,
    CounterpartyAccountStatus,
    CounterpartyAccountStatusSchema,
    CounterpartyAccountVerification,
    CounterpartyAccountVerificationResult,
    CounterpartyAccountVerificationResultSchema
} from '@webapps/numeral-ui-core'
import { Nullable } from '@/types'
import { chain, isObjectLike } from 'lodash'
import { CounterpartyAccountVerificationServiceResponse } from '@webapps/numeral-ui-core'

export function counterpartyAccountFactory(): CounterpartyAccount {
    const item: CounterpartyAccount = Object.create(null)
    const holderAddress: AccountHolderAddress = {
        country: '',
        building_number: '',
        street_name: '',
        postal_code: '',
        region_state: '',
        city: ''
    }

    item.type = undefined
    item.name = ''
    item.holder_name = ''
    item.account_number = ''
    item.bank_code = ''
    item.holder_address = holderAddress

    return item
}

export function canDisableCounterpartyAccountByStatus(status?: CounterpartyAccountStatus) {
    return status === CounterpartyAccountStatusSchema.enum.approved
}

export function canApproveOrDenyCounterpartyAccountByStatus(status?: CounterpartyAccountStatus) {
    return status === CounterpartyAccountStatusSchema.enum.pending_approval
}

/**
 * @description
 * Checks for specific verification result statuses like ["valid", "invalid"], etc.
 */
export function checkCounterpartyAccountVerificationResult(
    accountVerification: Nullable<CounterpartyAccountVerification>,
    verificationResults: CounterpartyAccountVerificationResult[]
) {
    if (!accountVerification) {
        return false
    }
    const accountVerificationServiceResponses = chain(accountVerification)
        .values()
        .filter(isObjectLike)
        .value() as CounterpartyAccountVerificationServiceResponse[]

    for (const result of accountVerificationServiceResponses) {
        if (verificationResults.includes(result?.result)) {
            return true
        }
    }
    return false
}

export function isCounterpartyAccountPendingVerificationResult(
    accountVerification?: Nullable<CounterpartyAccountVerification>
) {
    const { pending_verification } = CounterpartyAccountVerificationResultSchema.enum
    return accountVerification?.result === pending_verification
}
