import { ConfirmInfoModal } from '@/components'
import { If } from '@/components/@misc'
import { useConfiguration, useQueryFindCurrentUserInfo, useServices } from '@/hooks'
import { timeoutCallbackRunner } from '@/utils'
import { useQueryClient } from '@tanstack/react-query'
import { noop } from 'lodash'
import { PropsWithChildren, useCallback, useEffect, useMemo } from 'react'
import { INACTIVITY_LOGOUT_TIME } from './AuthProvider.const'
import { AuthContext } from './AuthProvider.context'
import { isUserInfoIncomplete } from './AuthProvider.utils'

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const queryClient = useQueryClient()
    const query = useQueryFindCurrentUserInfo()
    const { apiEnvironment } = useConfiguration()
    const { authenticationService } = useServices()

    const onLogin = useCallback(() => {
        authenticationService.login(apiEnvironment.primary?.url)
    }, [authenticationService, apiEnvironment])

    const onLogout = useCallback(async () => {
        await queryClient.cancelQueries().catch(noop)
        // Logout from the default environment first and let BFF redirect us to <ExtraLogout/> if needed
        authenticationService.logout(apiEnvironment.primary?.url)
    }, [authenticationService, apiEnvironment])

    const value = {
        onLogin,
        onLogout
    }

    const hasIncompleteUserInfo = useMemo(() => {
        return query?.data && isUserInfoIncomplete(query.data)
    }, [query])

    useEffect(() => {
        const cancelTimeoutCallbackRunner = timeoutCallbackRunner(onLogout, INACTIVITY_LOGOUT_TIME)

        return () => {
            cancelTimeoutCallbackRunner?.()
        }
    }, [onLogout])

    return (
        <AuthContext.Provider value={value}>
            <If condition={hasIncompleteUserInfo}>
                <ConfirmInfoModal userInfo={query.data} />
            </If>
            {children}
        </AuthContext.Provider>
    )
}
